@mixin control-animation($delay, $count) {
    > a.vd-btn {
        transform: scale(1);
        @for $i from 1 through $count {
            &:nth-child(#{$i - 1}) {
                transition-delay: #{($i - 1)*$delay}s;
            }
        }
    }
    > .block-button {
        > a.vd-btn {
            &:after {
                transform: scale(1);
            }
        }
    }
}
.block-price-section{
    &:hover {
        > .control {
            visibility: visible;
            opacity: 1;
            @include control-animation(0.015,20);
        }
    }
}
.block-pricing-table.block-inner.block-container{
    > .control.pricing-table-control{
        background-color: #4c5866;
        > a{
            background-color: #4c5866;
            &:hover{
                -webkit-filter: brightness(120%);
                filter: brightness(120%);
                background-color: #4c5866;
            }
        }
    }
    >.vd-border{
        background-color: #4c5866;
    }
}

.block-pricing-table-content
{
    display: flex;
    text-align:center;
    > .block-price-section
    {
        flex: 1;
        &.block-container
        {
            float: left;

            flex-grow:1;
            &.active-control
            {
                > .control
                {
                    visibility: visible;
                    opacity: 1;
                }
            }
        }
    }
}
.vd-pricing-table
{
    border-radius: 10px;
    &.style_2{
        > .vd-pricing-table-title{
            padding:30px 35px 25px;
            border-top-left-radius:0;
            border-top-right-radius:30px;
            > .h3{
                border-top-left-radius:0;
                border-top-right-radius:30px;
                font-size:23px;
            }
            > .h4{
                font-weight: bold;
                font-size:24px;
            }
            > .vd-price-container{
                > .vd-price-wrapper{
                    > .vd-price-curency{
                        font-size:30px;
                        font-weight: bold;
                        margin-top:13px;
                        opacity:1;
                    }
                    > .vd-price-text{
                        font-size:60px;
                        font-weight:bold;
                    }
                    > .vd-price-per{
                        bottom: 12px;
                        font-size: 17px;
                        font-weight: bold;
                        opacity: 1;
                    }
                }
            }
        }
        > .vd-price-bottom-container{
            display:none;
        }
    }
    > .vd-pricing-table-title
    {
        position: relative;

        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        background-color: #96c855;
        > .vd-pricing-table-icon
        {
            font-size: 20px;

            position: absolute;
            top: -17.5px;
            left: calc(50% - 17.5px);

            display: -webkit-box;

            display: -ms-flexbox;

            display: flex;

            width: 35px;
            height: 35px;

            color: #fff;
            border-radius: 50%;

            -webkit-box-pack: center;

            -ms-flex-pack: center;

            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-filter: brightness(110%);
            filter: brightness(110%);
        }
        > .h3
        {
            margin:0;
            padding-top: 15px;
            padding-bottom: 10px;

            text-align: center;

            min-height:26px;
            color: #fff;
            border-top-left-radius:10px;
            border-top-right-radius:10px;
        }
        > .h4
        {
            margin:0;

            text-align: center;

            min-height:26px;
            color: #fff;
        }
        > hr{
            margin:0;
            border-top-width:1px;
            border-top-style:solid;
        }
        > .vd-pracing-table-separator
        {
            display: block;

            width: 100%;
            height: 1px;

            background: #addf6c;
        }
        > .vd-price-container
        {
            padding: 5px 0;

            text-align: center;
            > .vd-price-wrapper
            {
                font-size: 50px;

                position: relative;

                color: #fff;
                > .vd-price-curency
                {
                    font-size: 14px;

                    position: absolute;
                    top: 0;
                    right: 100%;

                    margin-top: 5px;
                    margin-right: 5px;

                    opacity: .8;
                }
                > .vd-price-text
                {
                    font-size: 50px;
                    line-height: 80px;

                    position: relative;
                }
                > .vd-price-per
                {
                    font-size: 14px;

                    position: absolute;
                    bottom: 0;
                    left: 100%;

                    margin-left: 5px;

                    white-space: nowrap;

                    opacity: .8;
                }
            }
        }
    }
    > .vd-price-bottom-container
    {
        position: relative;

        width: 100%;
        height: 25px;

        color: #fff;
        /* background: #96c855;*/
        background: #fff;
        &:after
        {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;

            display: block;

            width: 100%;
            height: 25px;

            content: '';

            background: #96c855;

            -webkit-clip-path: polygon(50% 100%, 0 0, 100% 0);
            clip-path: polygon(50% 100%, 0 0, 100% 0);
            -webkit-clip-path: url('#vd-pricing-table-clip-shape');
            clip-path: url('#vd-pricing-table-clip-shape');
        }
    }
    > .vd-pricing-table-container
    {
        background-color: #fafafa;
        > .vd-pricing-table-button-container
        {
            padding-top: 5px;
            padding-right: 20px;
            padding-bottom: 5px;
            padding-left: 20px;
            > .vd-pricing-table-button
            {
                line-height: 40px;

                display: block;

                width: 100%;
                height: 40px;

                cursor: pointer;
                text-align: center;
                text-decoration: none;

                color: #535355;
                border: 1px solid #d2d2d2;
                border-radius: 6px;
                background-color: #f3f3f3;
                &:hover
                {
                    background-color: #f3f3f3;
                }
            }
        }
        > .vd-pricing-table-content
        {
            > .vd-pricing-table-feauture
            {
                font-size: 13px;
                line-height: 40px;

                min-height: 40px;
                padding-right: 20px;
                padding-left: 20px;

                color: #535355;
                border-bottom: 1px solid #e9e9e9;
            }
        }
    }
}
